import { React, CenterWrapper, View, Text, SEO, Page } from 'lib'
import { Theme, HTMLStylesDark, license, timestamp } from 'app'
import moment from 'moment'

function WebsiteLicenses(props) {

  const renderItem = (key, value) => {
    const type = 'License type(s): ' + value.licenses
    const repo = 'Online repository: ' + value.repository
    const url = 'License URL: ' + value.licenseUrl
    return (
      <View key={key} style={styles.itemWrapper}>
        <Text text={key} variant={['p1', 'greyscale1', 'veryBold']}/>
        <Text text={type} variant={['p3', 'greyscale2', 'marginTop:1', 'bold']}/>
        <Text text={repo} variant={['p3', 'greyscale2', 'marginTop:1', 'bold']}/>
        <Text text={url} variant={['p3', 'greyscale2', 'marginTop:1', 'bold']}/>
      </View>
    )
  }

  const packagesList = Object.keys(license).map((key) => renderItem(key, license[key]))
  const dateString = moment(timestamp).format('MMMM Do YYYY')

  return (
    <Page whiteBackground>
      <View style={styles.wrapper}>
        <SEO doNotTrack title={'Licenses'} description={'This website uses assets and third party open source packages that have their usage herein attributed.'}/>
        <CenterWrapper style={styles.wrapper} contentContainerStyle={styles.content}>
          <a href={'/'}><h1>Becon App</h1></a>
          <h2>Licenses</h2>
          <p>Thank you for visiting our website!</p>
          <p>This page was last updated on {dateString}</p>
          <br/>
          <p>Becon's website uses assets and third party open source packages that have their usage herein attributed:</p>
          <br/>
          <h4>Media, images and videos:</h4>
          <ul>
            <li><a href={'https://www.freepik.com/'}>Freepik</a></li>
            <li><a href={'https://unsplash.com/'}>Unsplash</a></li>
            <li><a href={'https://www.pexels.com/'}>Pexels</a></li>
          </ul>
          <br/>
          <h4>Open source software:</h4>
          {packagesList}
        </CenterWrapper>
      </View>
    </Page>
  )
}


const styles = {
  wrapper: {
    minHeight: '100vh',
    ...Theme.center,
    ...Theme.flex,
    flexDirection: 'column',
    ...Theme.marginVertical(6),
  },
  content: {
    ...HTMLStylesDark.plainText,
    display: 'flex',
    width: 'auto',
    maxWidth: Theme.values.maxTextContentWidth,
    flexDirection: 'column',
  },
  itemWrapper: {
    paddingTop: Theme.spacing(2),
    marginBottom: Theme.spacing(2),
  },
}

export default WebsiteLicenses
